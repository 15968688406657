// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-assortment-products-list-builder-assortment-products-list-builder-loadable-js": () => import("./../../../src/containers/AssortmentProductsListBuilder/AssortmentProductsListBuilderLoadable.js" /* webpackChunkName: "component---src-containers-assortment-products-list-builder-assortment-products-list-builder-loadable-js" */),
  "component---src-containers-checkout-builder-checkout-builder-js": () => import("./../../../src/containers/CheckoutBuilder/CheckoutBuilder.js" /* webpackChunkName: "component---src-containers-checkout-builder-checkout-builder-js" */),
  "component---src-containers-event-detail-builder-event-detail-builder-js": () => import("./../../../src/containers/EventDetailBuilder/EventDetailBuilder.js" /* webpackChunkName: "component---src-containers-event-detail-builder-event-detail-builder-js" */),
  "component---src-containers-newsletter-builder-newsletter-builder-js": () => import("./../../../src/containers/NewsletterBuilder/NewsletterBuilder.js" /* webpackChunkName: "component---src-containers-newsletter-builder-newsletter-builder-js" */),
  "component---src-containers-page-builder-page-builder-loadable-js": () => import("./../../../src/containers/PageBuilder/PageBuilderLoadable.js" /* webpackChunkName: "component---src-containers-page-builder-page-builder-loadable-js" */),
  "component---src-containers-product-detail-builder-product-detail-builder-loadable-js": () => import("./../../../src/containers/ProductDetailBuilder/ProductDetailBuilderLoadable.js" /* webpackChunkName: "component---src-containers-product-detail-builder-product-detail-builder-loadable-js" */)
}

